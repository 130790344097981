<template>
  <CRow>
    <div class="w-100" v-for="(list, index) in formattedLists" :key="index">
      <p class="w-100">
        <CListGroup>
          <CButton
              @click.prevent="toggleCollapse(index)"
              class="w-100 p-0 text-left"
          >
            <CListGroupItem href="#" color="secondary">
              <CRow>
                <CCol col="6" sm="8" md="9" lg="10" xl="10">
                  {{ list.name }}
                </CCol>
                <CCol col="6" sm="4" md="3" lg="2" class="text-right" @click.stop>
                  <CButtonGroup size="sm" role="group" aria-label="Small button group">
                    <CButton color="info" variant="outline" class="px-2 py-1"
                             @click="downloadFile(list.id)">
                      <CIcon name="cil-cloud-download"/>
                    </CButton>
                    <CButton color="success" variant="outline" class="px-2 py-1"
                             @click="changeData(list)">
                      <CIcon name="cil-pencil"/>
                    </CButton>
                    <CButton color="danger" variant="outline" class="px-2 py-1"
                             @click="removeFile(list.id)">
                      <CIcon name="cil-trash"/>
                    </CButton>
                  </CButtonGroup>
                </CCol>
              </CRow>
            </CListGroupItem>

          </CButton>
          <CCollapse :show="list.open" class="border-collapse px-0">
            <CCard body-wrapper class="m-0 border-0">
              <div class="panel-body">
                <p v-if="!list.description">...</p>
                <p v-else v-html="list.description"></p>
              </div>
            </CCard>
            <CCardFooter class="py-0 bg-color-gray">
              <CRow class="justify-content-between px-1">
                <p class="text-small text-dark m-0">
                  Посилання:
                  <btn-copy color="warning" :value="`${ copyLink(list.id)}`" class="p-1 px-2"/>
                  {{ copyLink(list.id) }}
                </p>
                <p class="text-small text-dark text-right m-0">{{ formatFileSize(list.size) }} / {{
                    list.updated_at
                  }}</p>
              </CRow>
            </CCardFooter>
          </CCollapse>
        </CListGroup>
      </p>
    </div>
  </CRow>
</template>
<script>
import BtnCopy from "~/Components/Button/BtnCopy.vue";
import {getDownloadFile, RemoveFile} from "@/api/fileManager";
import {downloadFileFormBlob} from "@/utils/fileUtils";
import {formatFileSize} from "@/mixins/interactionWithFiles";

export default {
  components: {BtnCopy},
  props: {
    lists: {
      require: true,
      type: Array,
      default: []
    }
  },
  data() {
    return {
      formattedLists: []
    }
  },
  watch: {
    lists: {
      immediate: true,
      deep: true,
      handler() {
        this.formattedListsF()
      }
    }
  },
  methods: {
    formatFileSize,
    formattedListsF() {
      console.log(this.$props.lists)
      this.formattedLists = this.$props.lists.map(item => ({...item, open: true}));
    },
    toggleCollapse(index) {
      this.formattedLists[index].open = !this.formattedLists[index].open;
    },
    removeFile(id) {
      this.$emit('deleteFile', id);
      // RemoveFile(id).then(() => this.$emit('getUpdate')).catch();
    },
    urlForDownload(id) {
      let route = this.$router.resolve({path: `/api/files/get/${id}`});
      return route.href;
    },
    copyLink(id) {
      return new URL(this.urlForDownload(id), window.location.origin).href
    },
    downloadFile(id) {
      let route = this.urlForDownload(id);
      window.open(route, '_blank');
      // getDownloadFile(id).then(resp => downloadFileFormBlob(resp)).catch();
    },
    changeData(list) {
      console.log('list')
      console.log(list)
      this.$emit('getChangeFileData', list);
    }
  }
}
</script>