<template>
  <CModal
      color="success"
      :title="`${changeFileData ? 'Редагування' : 'Завантаження'} файла`"
      :show.sync="showModal"
      size="lg"
      addContentClasses="srl"
  >
    <template #body-wrapper>
      <div class="p-2">
        <template v-if="error.length">
          <CAlert v-for="err in error" style="font-size: 12px;" show :color="err.color">
            {{ err.text }}
          </CAlert>
        </template>

        <drop-zone-file @targetFile="readFile" style="min-height: 150px;">
          <template v-slot:errorMessage>
            <CAlert v-if="fileError.length" style="font-size: 12px;" data-cy="msg_error" show color="danger">
              <ul style="list-style-type: none" class="p-0 m-0">
                <li v-for="err in fileError" :key="err">
                  {{ err }}
                </li>
              </ul>
            </CAlert>
          </template>
          <template v-slot:showFiles>
            <CRow>
              <template v-for="file in files">
                <CListGroup class="mb-2" layout="horizontal">
                  <CListGroupItem class="d-flex justify-content-between align-items-center">
                    <span>{{ file.name }}</span>
                    <CBadge color="warning">{{ formatFileSize(file.size) }}</CBadge>
                  </CListGroupItem>
                </CListGroup>
              </template>
            </CRow>
          </template>
        </drop-zone-file>
        <br>
        <CInput :disabled="!files.length" label="Назва файлу" placeholder="Назва файлу" v-model="name"
                :change="$v.name.$touch()"
                :class="{'error': $v.name.$error}"/>
        <small v-if="!$v.name.maxLength" class="form-text errorText w-100">Максимальна кількість
          символів
          {{ $v.name.$params.maxLength.max }}.</small>

        <label>Опис Файлу <span class="text-right text-secondary text-sm">({{
            descriptionLength
          }}/{{ $v.description.$params.maxLength.max }})</span></label>
        <CTextarea
            :disabled="!files.length"
            placeholder="Опис Файлу"
            rows="4"
            cols="15"
            :maxLength="$v.description.$params.maxLength.max + 1"
            :class="{'error': $v.name.$error}"
            @change="$v.description.$touch()"
            v-model="description"
        ></CTextarea>
        <small v-if="!$v.description.maxLength" class="form-text errorText w-100">Максимальна кількість
          символів
          {{ $v.description.$params.maxLength.max }}.</small>

      </div>
    </template>
    <template #footer>
      <CButton color="secondary" @click="closed">Відмінити</CButton>
      <CButton :disabled="loading" color="success" @click="upload">
        <CSpinner v-if="loading" color="Light" size="sm" class="align-items-center px-4"/>
        <span v-else>{{ changeFileData ? 'Оновити' : 'Завантажити' }}</span>
      </CButton>
    </template>
  </CModal>
</template>
<script>
import {required, minLength, maxLength} from 'vuelidate/lib/validators';
import DropZoneFile from "~/Components/DropZone/dropZoneFile.vue";
import {formatFileSize} from "@/mixins/interactionWithFiles";

export default {
  name: "ModalRefuseVerification",
  components: {DropZoneFile},
  props: {
    showModal: {
      required: true,
      default: false,
      type: Boolean
    },
    funcUploadFile: {
      required: true,
      default: () => {
      },
      type: Function
    },
    changeFileData: {
      required: false,
      type: Object,
      default: undefined

    }
  },
  data() {
    return {
      name: '',
      description: '',
      id: '',
      files: [],
      fileError: [],
      error: [],
      loading: false,
    }
  },
  validationFiles: ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.zip'],
  validations: {
    name: {
      required: true,
      maxLength: maxLength(150)
    },
    description: {
      maxLength: maxLength(500)
    }
  },
  methods: {
    formatFileSize,
    closed() {
      this.loading = false;
      this.$emit('close');
      this.clearing();
    },
    clearing() {
      this.name = '';
      this.description = '';
      this.id = '';
      this.files = [];
      this.fileError = [];
      this.error = [];
      this.$v.$reset();
    },
    readFile(files) {
      this.fileError = [];
      let localFiles = files[0];
      const maxSize = 10 * 1024 * 1024; // 10 МБ у байтах
      if (this.$options.validationFiles.some(ext => localFiles.name.endsWith(ext))) {
        if (localFiles.size >= maxSize) return this.fileError.push('Розмір файлу перевищує допустимих 10 МБ ');

        this.files = [];
        this.files.push(localFiles);
        this.name = this.files[0].name;
      } else this.fileError.push('Формат файла не відповідає дозволеним форматам');
    },
    validate() {
      if (!this.files.length) return this.error.push({
        text: 'Завантажте спочатку файл для збереження',
        color: 'danger'
      });
      if (!this.name.length) {
        this.name = this.files[0].name;
      }

      this.$v.$touch();
      return !!this.$v.$invalid;
    },
    async upload() {
      this.error = [];
      this.loading = true;

      if (this.validate()) {
        this.loading = false;
        return;
      }

      let formData;

      if (this.files[0] instanceof File) {
        formData = new FormData();
        formData.append('file', this.files[0]);
      }

      const obj = {name: this.name, description: this.description, file: formData, id: this.id};

      try {
        const response = await this.$props.funcUploadFile(obj);
        if (response === 'done') {
          this.error.push({text: 'Файл успішно завантажений та збережений!', color: 'success'});
          return setTimeout(() => this.closed(), 2000);
        } else return this.error.push({text: response, color: 'danger'});
      } catch (err) {
        this.error.push({text: 'Сталась помилка при завантаженні файлу.', color: 'danger'});
      }
      this.loading = false;
    }
  },
  watch: {
    showModal(val) {
      this.clearing();
      if (!val) this.closed();

      let {changeFileData} = this.$props;

      if (!!changeFileData && Object.keys(changeFileData).length) {
        let {name, description, ...rest} = {...changeFileData};
        this.name = name;
        this.description = description ? description : '';
        this.id = rest?.id;
        this.files.push({name: name, size: rest?.size ? rest.size : 0});
      }
      this.loading = false;
    }
  },
  computed: {
    descriptionLength() {
      return this.description.length;
    }
  }
}
</script>

<style lang="scss" scoped>
.form-item .errorText {
  display: none;
  //font-size: 1rem !important;
  color: #cb203d;
}

.form-item {
  &.errorInput .errorText {
    display: block;
  }
}

.error {
  border-color: #ff002a !important;
  border-style: solid !important;
  border-width: 2px !important;
  border-radius: .3rem;
}
</style>
