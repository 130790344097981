<template>
  <CRow>
    <CCol>
      <CCard>
        <CCardHeader>
          <CRow>
            <CCol><h2>Менеджер файлів</h2></CCol>
            <CCol class="text-right">
              <CButton color="secondary" @click.prevent="showFileEditorF(true)">Додати</CButton>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <list-collapse :lists="filesList" @getUpdate="getFilesList" @getChangeFileData="getChangeFileData"
                         @deleteFile="deleteFile"/>
        </CCardBody>
      </CCard>
    </CCol>
    <modal-file-manager-editor :show-modal="showFileEditor" @update:show-modal="showFileEditor = $event"
                               @close="showFileEditorF()" :func-upload-file="uploadFile"
                               :change-file-data="showFileEditorData"/>
    <modal-any-information
        :show-any-information-modal="showDeleteModal"
        color="danger"
        title="Видалення файлу"
    >
      <template v-slot:body>
        <!--        <h4>Ви справді бажаєте видалити файл?</h4>-->
        <h4>Увага: Ви збираєтесь видалити файл. Будь ласка, зверніть увагу, що ця дія є незворотною. Після видалення
          файлу він не може бути відновлений. Переконайтеся, що ви дійсно бажаєте видалити цей файл і впевнені у своєму
          рішенні.</h4>
      </template>
      <template v-slot:footer>
        <CButton color="secondary" class="btn btn-sm" @click.prevent="cancelDeleteFile">Скасувати</CButton>
        <CButton color="danger" class="btn btn-sm" @click.prevent="confirmDeleteFile">Підтверджую</CButton>
      </template>
    </modal-any-information>
  </CRow>
</template>
<script>
import ModalFileManagerEditor from "~/Components/Modal/ModalFileManagerEditor.vue";
import {uploadFile, getFileList, RemoveFile} from "@/api/fileManager";
import ListCollapse from "~/FileManager/ListCollapse.vue";
import ModalAnyInformation from "~/Components/Modal/ModalAnyInformation.vue";

export default {
  name: 'FileManager',
  components: {ModalAnyInformation, ListCollapse, ModalFileManagerEditor},
  data() {
    return {
      showFileEditor: false,
      showDeleteModal: false,
      showFileEditorData: undefined,
      filesList: []
    }
  },
  methods: {
    uploadFile,
    showFileEditorF(show = false, data = null) {
      this.showFileEditor = show;
      this.showFileEditorData = data;
      if (!data) this.getFilesList();
    },
    getChangeFileData(date) {
      console.log('data', date);
      this.showFileEditorF(true, date)
    },
    getFilesList() {
      getFileList().then((resp) => {
        console.log(resp)
        this.filesList = [...resp[0]]
      });
    },
    deleteModal(show = false) {
      this.showDeleteModal = show;
    },
    deleteFile(id) {
      // this.deleteModal(true);
      //   here must waite answer from modal (confirmDeleteFile or deleteModal)
      return new Promise((resolve, reject) => {
        this.deleteModal(true);
        const fileId = id;

        const handleConfirmation = () => {
          RemoveFile(id)
              .then(() => resolve(this.getFilesList()))
              .catch((error) => reject(error));

          this.$off('confirm', handleConfirmation);
          this.deleteModal(false);
        }

        this.$on('confirm', handleConfirmation);
      });

    },
    confirmDeleteFile() {
      this.$emit('confirm');
    },
    cancelDeleteFile() {
      this.$off('confirm');
      this.deleteModal(false);
    }
  },
  mounted() {
    console.log('mounted')
    this.getFilesList();
  }
}
</script>