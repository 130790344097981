import axios from 'axios';

let validationKeys = ['name', 'description', 'file'];
const ApiUploadFile = async (file, id = '') => {
    try {
        const url = id ? `/api/files/${id}` : '/api/files';
        const method = id ? 'patch' : 'post';
        return await axios[method](url, file).then(e => id ? id : e.data.id);
    } catch (e) {
        return e.response.data;
    }
}
// const ApiUploadFile = (file, id = '') => {
//     const url = id ? `/api/files/${id}` : '/api/files';
//     const method = id ? 'patch' : 'post';
//
//     return new Promise((resolve, reject) => {
//         axios[method](url, file)
//             .then(resp => resolve(resp.data.id))
//             .catch(err => reject(err.response.data));
//     })
// }

const ApiUpdateMetaFile = async (id, {name, description}) => {
    try {
        return await axios.patch(`/api/files/${id}/meta`, {name, description}).then(e => e.data || e.status);
    } catch (e) {
        return e.response.data;
    }
}
export const uploadFile = ({name, description, file, ...rest}) => {
    //if (!validationKeys.every(key => dataFile.hasOwnProperty(key))) return 'Відсутні елементи';
    return new Promise((resolve, reject) => {
        let responseID = '';
        let uploadPromise;
        if (file) {
            uploadPromise = rest?.id && rest.id ? ApiUploadFile(file, rest.id) : ApiUploadFile(file);
        } else {
            uploadPromise = Promise.resolve(rest.id);
        }

        uploadPromise.then(async (responseFileId) => {
            responseID = responseFileId;
            return await ApiUpdateMetaFile(responseID, {name, description});
        }).then(responseMeta => {
            if (responseMeta === 200 || responseMeta === 204 || responseMeta.length) resolve('done');
            else reject('Error in ApiUpdateMetaFile');
        }).catch(error => reject(error.response.data));
    });
}

const ApiGetFiles = () => {
    return new Promise((resolve, reject) => {
        axios.get('/api/files')
            .then(resp => resolve(resp.data))
            .catch(err => reject(err.response.data))
    })
}

export const getFileList = () => {
    console.log('getFileList')
    return new Promise((resolve, reject) => {
        console.log('getFileList => Promise')
        Promise.all([ApiGetFiles()])
            .then((value) => resolve(value))
            .catch(err => reject(err));
    });
    // const response = await ApiGetFiles();
    // return response;
}

const ApiRemoveFile = (id) => {
    return new Promise((resolve, reject) => {
        axios.delete(`/api/files/${id}`)
            .then(resp => resolve(resp.data))
            .catch(err => reject(err.response.data))
    })
}

export const RemoveFile = (id) => {
    return new Promise((resolve, reject) => {
        if (!id) return reject('ID is empty!');
        Promise.all([ApiRemoveFile(id)])
            .then(resp => resolve(resp.data))
            .catch(err => reject(err.response.data))
    })
}


const ApiDownloadFile = (id) => {
    return new Promise((resolve, reject) => {
        axios.get(`/api/files/get/${id}`)
            .then(resp => resolve(resp.data))
            .catch(err => reject(err.response.data));
    });
}

export const getDownloadFile = (id) => {
    return new Promise((resolve, reject) => {
        if (!id) return reject('ID is empty!');
        Promise.all([ApiDownloadFile(id)])
            .then(resp => resolve(resp.data))
            .catch(err => reject(err.response.data))
    })
}