<template>
    <div
            @dragenter.prevent="toggleActive"
            @dragleave.prevent="toggleActive"
            @dragover.prevent
            @drop.prevent="dropFiles"
            :class="{
                'active-dropzone': active,
                'hasFile': hasFiles}"
            class="dropzone"
    >
        <div class="isLineText">
            <span>Перемістіть сюди файли АБО</span>
            <label for="dropzoneFile">Виберіть файл</label>
        </div>
        <input type="file" id="dropzoneFile" class="dropzoneFile" value="4000" multiple @change="readFile">
        <div class="mx-2" ref="fileContainer">
            <slot name="errorMessage"></slot>
            <slot name="showFiles" class="slotValue"></slot>
        </div>
    </div>
</template>

<script lang="js">
export default {
    name: 'dropZoneFile',
    props: {
        isLineText: {
            require: false,
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            active: false,
            hasFiles: false
        }
    },
    methods: {
        toggleActive() {
            this.active = !this.active;
        },
        dropFiles(e) {
            this.toggleActive()
            console.log(e)
            const files = e.dataTransfer.files;
            this.$emit('targetFile', files);
            this.checkFileContainer();
        },
        readFile(e) {
            console.log(e)
            const files = e.target.files;
            //this.hasFiles = files.length > 0;
            this.$emit('targetFile', files);
            this.checkFileContainer();
        },
        checkFileContainer() {
            const fileContainer = this.$refs.fileContainer;
            this.hasFiles = fileContainer && fileContainer.children.length > 0;
        }
    }
}
</script>

<style lang="scss" scoped>
.hasFile{
    justify-content: flex-start;
}
.dropzone {
  width: 100%;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  //border: 2px dashed #77b5f2;
  border: 2px dashed #6095ca;
  background-color: #dfdfdf;
  transition: 0.1s ease all;
  border-radius: 5px;
  padding: 10px 15px;
  //align-items: center;
  justify-content: center;

  label {
    padding: 0 10px;
    color: #0076ff;
    border-bottom: 1px dashed #0076ff;
    transition: 0.1s ease all;
    cursor: pointer;
    margin: 0 5px;

    &:focus {
      color: #233a51;
      border-bottom: 1px solid #233a51;
    }
  }

  input {
    display: none;
  }
}

.active-dropzone {
  color: #335476;
  border-color: #335476;
  background-color: #c1c1c1;

  label {
    color: #233a51;
    border-bottom: 1px solid #233a51;
  }
}

.isLineText {
  color: #0683ff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;

  label {
    color: #0076ff;
    border-bottom: 1px dashed #0076ff;

    &:focus {
      color: #233a51;
      border-bottom: 1px solid #233a51;
    }
  }

  span {
    text-align: center;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>